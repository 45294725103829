:root {
    // Assistant variables
    /* Font Family */
    --font-family: 'Inter', sans-serif;
  
    /* Primary Colors */
    --primary-color: #24435c; /* Softer blue */
    --secondary-color: #ffffff;
  
    /* Background Colors */
    --background-color: #f5f7fa; /* Light gray-blue */
    --dark-background-color: #ffffff; /* For chat windows */
  
    /* Text Colors */
    --text-color: #2d3436; /* Darker for better contrast */
    --secondary-text-color: #6e7191; /* Adjust this color as needed */
  
    /* Message Colors */
    --user-message-color: #24435c;
    --assistant-message-color: #f3f7f8;
  
    /* Other Colors */
    --light-gray: #dfe6e9;
    --dark-gray: #636e72;
    --shadow-color: rgba(0, 0, 0, 0.1);
  
    /* Border Radius */
    --border-radius: 12px;
  }

@import url("@arcomusic-ai/ui/styles.css");

.App {
    width: 100%;
    height: 100vh;
}

.contentWrapper {
    max-width: 1400px;
    width: 90%;
    margin: 0 auto;
}


.pageTitle {
    font-size: 26px;
    font-weight: 600;
}

.pageSearchWrapper {
    background: #F5F6F8;
    display: flex;
    border-radius: 30px;
    padding: 8px 20px;
    width: 400px;
    margin-left: 40px;
    border: 1px solid transparent;
}

.pageSearchWrapper:focus-within {
    border: 1px solid #ACACB7;
}

.pageSearch {
    outline: none;
    background: transparent;
    margin-left: 10px;
    width: 100%;

    &::placeholder {
        color: #ACACB7;
    }

    &:focus {
        outline: none !important;
    }
}

// dialog

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    border-radius: 12px !important;
    color: #252525;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    overflow: visible;
}

.MuiBackdrop-root.MuiModal-backdrop {
    background: #395e7c4b;
}

.dialog {
    padding: 20px 30px 30px 30px !important;
}

.popupLoaderWrapper {
    background: #ffffff7a;
    position: absolute;
    top: 0;
    border-radius: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 640px) {
    .MuiDialog-container .MuiPaper-root {
        margin: 0 !important;
        width: 100%;
    }
}

.hover_custom {
    &:hover {
        opacity: 0.6;
        cursor: pointer;
    }
}